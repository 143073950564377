import * as React from "react";
import ReactToPrint from "react-to-print";
import { Loading2 } from "../../components/Loading";
import { useNIDContext } from "../../context/NIDContext";

import PrintArea from "./PrintArea";

export default function PrintAreaToPdf() {
  const { state } = useNIDContext();
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);

  const [text, setText] = React.useState("old boring text");
  const [finished, set_finished] = React.useState(0);

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        resolve();
        setText("New, Updated Text!");
        setTimeout(() => {
          setLoading(false);
          set_finished(1);
        }, 4500);
      }, 100);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <button className="bg-green-600 text-sm font-medium tracking-wide px-6 py-2 rounded text-white shadow-lg border focus:ring-1 focus:ring-offset-1 focus:ring-green-600 outline-none">
        Save NID
      </button>
    );
  }, []);

  return (
    <div>
      <PrintArea ref={componentRef} text={text} />

      <div className="flex justify-center py-12 opacity_print_mobile">
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle={"nid-" + state?.nid?.nid_no}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
      </div>
      {loading && <Loading2 />}
    </div>
  );
}
