import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faChevronDown,
  faUser,
  faSignOut,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

import { useAuthContext } from "../../context/AuthContext";

const Header = () => {
  const { state, logout } = useAuthContext();

  const [open_profile_opt, set_opo] = useState(false);

  return (
    <nav className="bg-gray-50 shadow hide_print_mobile">
      <div className="container relative flex items-center justify-between h-[75px]">
        <Link to="/" className="flex items-center gap-x-2">
          <>
            <img
              className="h-14"
              src="https://i.ibb.co/qNQjY8X/logo.png"
              alt=""
            />
            <span className="hidden md:block text-3xl xl:text-4xl font-medium">
              জাতীয় পরিচয় পত্র ডাউনলোড সিস্টেম
            </span>
          </>
        </Link>

        <div>
          {state?.authenticated ? (
            <div className="relative w-fit">
              <button
                onClick={() => set_opo((p) => !p)}
                onBlur={() =>
                  setTimeout(() => {
                    set_opo(false);
                  }, 500)
                }
                type="button"
                className={`w-full py-2 px-3 rounded bg-gray-100 shadow border flex items-center justify-center gap-x-3`}
              >
                <span> {state?.user?.name} </span>

                <img
                  className="h-8 w-8 rounded-full"
                  src={state?.user?.photo}
                  alt=""
                />
                <FontAwesomeIcon
                  className={`text-sm text-gray-500 transition-all transform ${
                    open_profile_opt ? "rotate-180" : "rotate-0"
                  }`}
                  icon={faChevronDown}
                />
              </button>

              <div
                className={`absolute w-full bg-white rounded shadow top-full right-0 transition-all origin-top transform ${
                  open_profile_opt ? "scale-y-100" : "scale-y-0"
                }`}
              >
                <Link
                  to="/profile"
                  className="flex items-center gap-x-2 w-full hover:bg-gray-100 p-3"
                >
                  <>
                    <FontAwesomeIcon
                      className="text-base text-gray-500"
                      icon={faUser}
                    />
                    <span className="text-sm font-medium">Profile</span>
                  </>
                </Link>
                <Link
                  to="/preview-nid"
                  className="flex items-center gap-x-2 w-full hover:bg-gray-100 p-3"
                >
                  <>
                    <FontAwesomeIcon
                      className="text-base text-gray-500"
                      icon={faPlay}
                    />
                    <span className="text-sm font-medium">Preview</span>
                  </>
                </Link>
                <button
                  onClick={logout}
                  className="flex items-center gap-x-2 w-full  hover:bg-gray-100 p-3"
                >
                  <FontAwesomeIcon
                    className="text-base text-gray-500"
                    icon={faSignOut}
                  />
                  <span className="text-sm font-medium">Logout</span>
                  {state?.loading && (
                    <span className="block w-4 h-4 border border-black rounded-full border-t-transparent animate-spin ml-auto" />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <>
              <NavLink to="/signup">
                <button className="mr-6 hover:text-[#3b5998]">
                  <span>Signup</span>
                </button>
              </NavLink>
              <NavLink to="/signin">
                <button
                  type="button"
                  className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-2 mr-2"
                >
                  <FontAwesomeIcon icon={faLock} />
                  Sign in
                </button>
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
