import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBhDyq4VZwDPiIJv-_wd3DDfvIruGoJck8",
  authDomain: "nidw-f896f.firebaseapp.com",
  databaseURL: "https://nidw-f896f-default-rtdb.firebaseio.com",
  projectId: "nidw-f896f",
  storageBucket: "nidw-f896f.appspot.com",
  messagingSenderId: "259444564813",
  appId: "1:259444564813:web:b19809737e8d9597518d04",
  measurementId: "G-N3VWK6L8CG",

  // apiKey: "AIzaSyBhDyq4VZwDPiIJv-_wd3DDfvIruGoJck8",
  // authDomain: "nidw-f896f.firebaseapp.com",
  // databaseURL:
  //   "https://nidw-f896f-default-rtdb.firebaseio.com",
  // projectId: "nidw-f896f",
  // storageBucket: "nidw-f896f.appspot.com",
  // messagingSenderId: "259444564813",
  // appId: "1:259444564813:web:b19809737e8d9597518d04",
  // measurementId: "G-N3VWK6L8CG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getDatabase();
export { app, analytics, auth, db };
